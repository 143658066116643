import { observer } from 'mobx-react';

import { useCallback, useEffect } from "react";

import { COLORS, ICONS } from "shared/constants";
import { useStore } from "shared/hooks";
import { UserDashboardWidgetDictionaryItemModel, UserDashboardWidgetModel } from "shared/models";
import { UserDashboardWidgetItemModel } from "shared/models/UserDashboardModel";
import { UiIcon, UiModal } from "shared/uikit";
import { currency, number } from "shared/utilities";

import { PPDWidgetGroups } from "../PPDWidgetGroups";
import { PPDWidgetItemBonus } from "../PPDWidgetItemBonus";
import { PPDWidgetItemValue } from "../PPDWidgetItemValue";

import './styles.scss';

type PropsType = {
    widget: UserDashboardWidgetModel
}

export const PPDWidgetBaseAndCompareMobile = observer(({ widget }: PropsType) => {
    const store = useStore(() => ({
        isLoading: true,
        groupId: null as number | string | null,
        storeId: null as number | string | null,
        stores: [] as UserDashboardWidgetDictionaryItemModel[],
        isOpened: false,
    }));

    const modal = useStore(() => ({
        isOpened: false,
        store: null as null | UserDashboardWidgetDictionaryItemModel,
        group: null as null | UserDashboardWidgetDictionaryItemModel,
        item: null as null | UserDashboardWidgetItemModel
    }))

    const validatedGroups = useCallback((storeId: number | string | null) => {
        const items = widget.items.filter(item => item.storeId === storeId);
        return widget.groups.filter(group => items.some(item => item.groupId === group.id));
    }, [widget]);

    useEffect(() => {
        //validate that all store has any items
        const stores = widget.stores.filter(store => widget.items.some(item => item.storeId === store.id));
        let defaultStore = stores.find(store => store.id === null);
        if (!defaultStore && stores.length > 0) {
            defaultStore = stores[0];
        }

        store.set("stores", stores);
        store.set("storeId", defaultStore?.id);
        store.set("storeId", defaultStore?.id);

        const groups = validatedGroups(store.storeId);
        if (groups.length > 0) {
            store.set("groupId", groups[0].id);
        }
        store.set("isLoading", false);
    }, [store, widget, validatedGroups]);

    const groups = validatedGroups(store.storeId);
    const item = widget.items.find(item => item.groupId === store.groupId && item.storeId === null);

    return (
        <div className={'ppd-widget ppd-widget--base-and-compare-mobile'}>
            <div onClick={() => store.set("isOpened", !store.isOpened)} className="ppd-widget__header">
                <UiIcon size={16} color={COLORS.BLACK} icon={store.isOpened ? ICONS.CHEVRON_UP : ICONS.CHEVRON_DOWN}/>
                <div className="ppd-widget__name">{widget.name}</div>
                {item && (
                    <div className="ppd-widget__value">
                        <PPDWidgetItemValue
                            typeId={widget.typeId}
                            color={item.color}
                            value={item.value}
                            fontSize={[24, 14]}
                            iconSize={[16, 10]}
                        />
                    </div>
                )}
            </div>
            {store.isOpened && (
                <>
                    {groups.length > 0 && (
                        <div className="ppd-widget__groups">
                            <PPDWidgetGroups
                                items={groups}
                                value={store.groupId}
                                name={"groupId"}
                                onChange={store.handleChange}
                            />
                        </div>
                    )}
                    {item?.bonus && (
                        <div className={`ppd-widget__bonus`}>
                            <UiIcon
                                icon={ICONS.COINS}
                                color={'#F7B731'}
                                size={16}
                            />
                            <span>{currency(item.bonus.value)}</span>
                        </div>
                    )}
                    <div className="ppd-widget__items">
                        {widget.stores.map(storeItem => {
                            const item = widget.items.find(item => item.groupId === store.groupId && item.storeId === storeItem.id);
                            if (!item) {
                                return null;
                            }
                            return (
                                <div
                                    key={storeItem.id}
                                    className="ppd-widget-item"
                                    onClick={() => {
                                        modal.set("item", item);
                                        modal.set("store", storeItem);
                                        modal.set("group", widget.groups.find(group => group.id === item?.groupId) || null);
                                        modal.set("isOpened", true);
                                    }}
                                >
                                    <div className="ppd-widget-item__name">{storeItem.name}</div>
                                    <div className="ppd-widget-item__inner">
                                        {widget.typeId === 'base' && (
                                            <div className="ppd-widget-item__fact">{currency(number(item.fact), ' ')}{widget.units.long}</div>
                                        )}
                                        <div className={`ppd-widget-item__value`}>
                                            <PPDWidgetItemValue
                                                typeId={widget.typeId}
                                                color={item.color}
                                                value={item.value}
                                                fontSize={18}
                                                iconSize={[18, 12]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </>
            )}
            {modal.item && (
                <UiModal
                    className={'ppd-widget--base-and-compare-mobile'}
                    title={' '}
                    isOpened={modal.isOpened}
                    onClose={() => modal.set("isOpened", false)}
                >
                    {modal.store && <div className="ppd-widget-modal__store">{modal.store.name}</div>}
                    {modal.group && <div className="ppd-widget-modal__group">{modal.group.name}</div>}
                    <div className="ppd-widget-modal__value ppd-widgets--colored-value">
                        <PPDWidgetItemValue
                            typeId={widget.typeId}
                            value={modal.item.value}
                            color={modal.item.color}
                            fontSize={[20, 32]}
                            iconSize={[32, 14]}
                            progressSize={100}
                        />
                    </div>
                    {(modal.item.valueCompare && modal.item.valueCompare.length > 0) && (
                        <div className="ppd-widget-modal-compare">
                            {modal.item.valueCompare.map(item => (
                                <div key={item[0]} className="ppd-widget-modal-compare__item">
                                    {item.map(val => (<div key={val}>{val}</div>))}
                                </div>
                            ))}
                        </div>
                    )}
                    <div className="ppd-widget-modal__fact">
                        <label>Факт</label>
                        <span>{currency(number(modal.item.fact), ' ')}{widget.units.short}</span>
                    </div>
                    <div className="ppd-widget-modal__fact">
                        <label>План</label>
                        <span>{currency(number(modal.item.plan), ' ')}{widget.units.short}</span>
                    </div>
                    <div className="ppd-widget-modal__bonus">
                        <PPDWidgetItemBonus bonus={modal.item.bonus}/>
                    </div>
                </UiModal>
            )}
        </div>
    );
});
