import classnames from "classnames";
import { observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';
import { ICONS } from "shared/constants";

import { useStore } from "shared/hooks";
import { UserDashboardWidgetModel } from "shared/models";
import { UiIcon } from "shared/uikit";
import { currency } from "shared/utilities";
import { PPDWidgetGroups } from "../PPDWidgetGroups";

import './styles.scss';

import { PPDWidgetItemValue } from "../PPDWidgetItemValue";
import { toJS } from 'mobx';

type PropsType = {
    widget: UserDashboardWidgetModel
}

export const PPDWidgetTable = observer(({ widget }: PropsType) => {
    const store = useStore(() => ({
        groups: [] as typeof widget.groups,
        groupId: null as string | null,
    }))

    useEffect(() => {
        const groups = widget.groups
            .slice()
            .filter(group => widget.items.some(item => item.groupId === group.id));

        if (groups.length > 0) {
            store.set("groupId", groups[0].id);
            store.set("groups", groups);
        }
    }, [widget, store]);

    const item = useMemo(() => {
        return widget.items.find(item => item.groupId === store.groupId && item.storeId === null) || null;
    }, [widget, store.groupId]);

    if (!item) {
        return null;
    }

    const items = widget.items
        .filter(item => item.groupId === store.groupId && item.storeId !== null);

    const hasBonusColumn = items.some(item => !!item.bonus);

    return (
        <div className={'ppd-widget ppd-widget--table'}>
            <div className="ppd-widget__header">
                <div className="ppd-widget__name">{widget.name}</div>
                {item && (
                    <PPDWidgetItemValue
                        value={item.value}
                        typeId={widget.typeId}
                        color={item.color}
                        fontSize={24}
                        iconSize={[24, 12]}
                    />
                )}
            </div>
            <div className="ppd-widget__subheader">
                <PPDWidgetGroups
                    className="ppd-widget__groups"
                    items={store.groups}
                    value={store.groupId}
                    name={'groupId'}
                    onChange={store.handleChange}
                />
                {!item.bonus && (
                    <div className="ppd-widget-bonus">
                        <span>Бонус не начисляется</span>
                    </div>
                )}
                {!!item.bonus && (
                    <div className={classnames("ppd-widget-bonus", `ppd-widget-bonus--${item.bonus.statusId}`)}>
                        <UiIcon size={18} icon={ICONS.COINS} color={item.bonus.statusId === 'received' ? "#f7b731" : '#babec4'}/>
                        <div className="ppd-widget-bonus__value">
                            {currency(item.bonus.value)}
                        </div>
                    </div>
                )}
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Бутик</th>
                        <th>План</th>
                        <th>Факт</th>
                        <th>%</th>
                        {hasBonusColumn && <th>Бонус</th>}
                    </tr>
                </thead>
                <tbody>
                    {items.map(item => {
                        return (
                            <tr key={item.storeId}>
                                <td>{widget.storeById[item.storeId as number]?.name || 'Не определено'}</td>
                                <td>
                                    <div>{item.plan} {widget.units.short}</div>
                                    {!!item.planHint && (
                                        <div className={`ppd-widget-color--${item.color}`}>{item.planHint}</div>
                                    )}
                                </td>
                                <td>
                                    <div>{item.fact} {widget.units.short}</div>
                                    {(!!item.factHint && !item.planIsDone) && (
                                        <div className={`ppd-widget-color--${item.color}`}>{item.factHint}</div>
                                    )}
                                    {(!!item.planIsDone) && (
                                        <div><UiIcon size={14} icon={ICONS.CHECK} color={'#20bf6b'}/></div>
                                    )}
                                </td>
                                <td>
                                    <PPDWidgetItemValue
                                        typeId={widget.typeId}
                                        value={item.value}
                                        color={item.color}
                                        fontSize={14}
                                        iconSize={[16, 10]}
                                    />
                                    {item.valueCompare.length > 0 && (
                                        <div className={'ppd-widget-item__compare'}>
                                            {item.valueCompare.map((val, index) => (
                                                <div key={index}>{val.join(': ')}</div>
                                            ))}
                                        </div>
                                    )}
                                </td>
                                {hasBonusColumn && (
                                    <td>
                                        {!!item.bonus && (
                                            <div className={classnames('ppd-widget-item-bonus', `ppd-widget-item-bonus--${item.bonus.statusId}`)}>
                                                <UiIcon
                                                    size={14}
                                                    color={item.bonus.statusId === 'received' ? '#20bf6b' : '#a1a4aa'}
                                                    icon={item.bonus.statusId === 'received' ? ICONS.CHECK : ICONS.LOCK}
                                                />
                                                <span>{currency(item.bonus.value)}</span>
                                            </div>
                                        )}
                                    </td>
                                )}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    );
});
