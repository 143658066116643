import lodash from "lodash";
import { observer } from 'mobx-react';
import { useMedia, useStore } from "shared/hooks";
import { IUserDashboardBonusesModel } from "shared/models";
import { UiModal } from "shared/uikit";

import { currency } from "shared/utilities";

import './styles.scss';

const image = require('./assets/image.svg').default;

type PropsType = {
    tab: string
    bonuses?: IUserDashboardBonusesModel | null
}

export const PPDBonuses = observer(({ bonuses, tab }: PropsType) => {
    const store = useStore(() => ({
        isOpened: false,
    }))
    const { is320, is768, is1024 } = useMedia();
    if (!bonuses || bonuses.items.length === 0) {
        return null;
    }

    const bonusesItemsByGroupId = lodash.groupBy(bonuses.items, 'groupId');
    const bonusesItemsWithoutGroup = bonuses.items.filter(item => !item.groupId);

    const total = bonuses.items.reduce((value, item) => {
        return +value + +(item.value || 0);
    }, 0);

    const headerText = (tab: string) => {
        if(tab === 'month'){
            return 'Итого бонусов к выплате в этом месяце';
        }

        if(tab === 'quarter'){
            return 'Итого бонусов к выплате в этом квартале';
        }

        return 'Итого бонусов к выплате в этом году';
    }

    if (is320 || is768 || is1024) {
        return (
            <>
                <div className={'ppd-bonuses ppd-bonuses--preview'} onClick={() => store.set("isOpened", !store.isOpened)}>
                    <div className="ppd-bonuses-header">
                        <div className="ppd-bonuses-header__label">
                            Бонусы к выплате
                        </div>
                        <div className="ppd-bonuses-header__value">
                            <i style={{ backgroundImage: `url(${image})` }}/>
                            <span>{currency(total)}</span>
                        </div>
                    </div>
                </div>
                <UiModal
                    isOpened={store.isOpened}
                    title={' '}
                    onClose={() => store.set("isOpened", false)}
                    className={'ppd-bonuses-modal'}
                    isAside={is768 || is1024}
                >
                    {tab === 'month' && (
                        <div className="ppd-bonuses-header">
                            <div className="ppd-bonuses-header__label">{headerText(tab)}</div>
                            <div className="ppd-bonuses-header__value">
                                <i style={{ backgroundImage: `url(${image})` }}/>
                                <span>{currency(total)}</span>
                            </div>
                        </div>
                    )}
                    {bonuses.groups.map(group => {
                        const items = bonusesItemsByGroupId[`${group.id}`] ?? [];
                        const value = items.reduce((value, item) => value + +(item.value || 0), 0);
                        if (items.length === 0) {
                            return null;
                        }
                        return (
                            <div key={group.id} className={'ppd-bonuses-group'}>
                                <div className="ppd-bonuses-group__inner">
                                    <div className="ppd-bonuses-group__name">{group.name}</div>
                                    <div className="ppd-bonuses-group__value">{currency(value)}</div>
                                </div>
                                <div className="ppd-bonuses-group__items">
                                    {items.map((item, index) => (
                                        <div key={index} className="ppd-bonuses-item">
                                            <div className="ppd-bonuses-item__name">{item.name}</div>
                                            <span>..........................................................................................................................................................................</span>
                                            <div className="ppd-bonuses-item__value">{currency(+(item.value || 0))}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        );
                    })}
                    {bonusesItemsWithoutGroup.map((item, index) => (
                        <div key={index} className="ppd-bonuses-item">
                            <div className="ppd-bonuses-item__name">{item.name}</div>
                            <span>..........................................................................................................................................................................</span>
                            <div className="ppd-bonuses-item__value">{currency(+(item.value || 0))}</div>
                        </div>
                    ))}
                </UiModal>
            </>

        )
    }

    return (
        <div className={'ppd-bonuses'}>
            {tab === 'month' && (
                <div className="ppd-bonuses-header">
                    <div className="ppd-bonuses-header__label">{headerText(tab)}</div>
                    <div className="ppd-bonuses-header__value">
                        <i style={{ backgroundImage: `url(${image})` }}/>
                        <span>{currency(total)}</span>
                    </div>
                </div>
            )}
            {bonuses.groups.map(group => {
                const items = bonusesItemsByGroupId[`${group.id}`] ?? [];
                const value = items.reduce((value, item) => value + +(item.value || 0), 0);
                if (items.length === 0) {
                    return null;
                }
                return (
                    <div key={group.id} className={'ppd-bonuses-group'}>
                        <div className="ppd-bonuses-group__inner">
                            <div className="ppd-bonuses-group__name">{group.name}</div>
                            <div className="ppd-bonuses-group__value">{currency(value)}</div>
                        </div>
                        <div className="ppd-bonuses-group__items">
                            {items.map((item, index) => (
                                <div key={index} className="ppd-bonuses-item">
                                    <div className="ppd-bonuses-item__name">{item.name}</div>
                                    <span>..........................................................................................................................................................................</span>
                                    <div className="ppd-bonuses-item__value">{currency(+(item.value || 0))}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                );
            })}
            {bonusesItemsWithoutGroup.map((item, index) => (
                <div key={index} className="ppd-bonuses-item">
                    <div className="ppd-bonuses-item__name">{item.name}</div>
                    <span>..........................................................................................................................................................................</span>
                    <div className="ppd-bonuses-item__value">{currency(+(item.value || 0))}</div>
                </div>
            ))}
        </div>
    );
});
